import { combineReducers } from "redux";
import { drawerReducer } from "reducers/drawer/reducer";
import { snackbarReducer } from "reducers/snackbar/reducer";

const rootReducers = combineReducers({
  drawer: drawerReducer,
  snackbar: snackbarReducer,
});

export default rootReducers;
export type RootState = ReturnType<typeof rootReducers>;
